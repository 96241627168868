<template>
  <div>

    <QuestionTitle :question="question"></QuestionTitle>
    <div class="col-sm-12">
      <fieldset>

        <div v-for="(o, i) in question.options" v-bind:key="i" class="form-check mb-2">
          <input @update:modelValue="onInput" class="form-check-input" v-model="options" type="radio"
                 :value="o">
          <label class="form-check-label">
            {{ o }}
          </label>
        </div>

      </fieldset>
    </div>

  </div>
</template>

<script>
import QuestionTitle from "../QuestionTitle";

export default {
  name: "RadioInput",
  props: ["question", "modelValue"],
  components: {QuestionTitle},

  emits: ['update:modelValue'],

  data() {
    return {
      options: this.modelValue.options[0]
    }
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', {
        options: [value]
      });
    },

    validate() {
      return true;
    }
  },
}
</script>

<style scoped>

</style>