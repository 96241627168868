<template>
  <div>
    <div v-if="schema">
      <div class="head-anchor"></div>
      <div v-if="currentSection !== 0" class="card p-3 mb-3 m-1 section-header">
        <div>
          <button v-if="currentSection > 0" @click="back" class="btn btn-primary">Zurück</button>
        </div>
      </div>
      <p class="ps-2">Alle Fragen ohne <span class="required">*</span> sind optional</p>


      <div v-for="(question, i) in schema.sections[currentSection].questions" v-bind:key="i" class="row mb-3 m-1">
        <div class="card p-3">
          <TextInput v-if="question.type === 'text'"
                     :question="question"
                     :model-value="submit.sections[currentSection].answers[i]"
                     @update:modelValue="ev => update(currentSection, i, ev)"
                     :ref="currentSection + '-' + i"
          />
          <RadioInput v-else-if="question.type === 'radiobutton'"
                      :question="question"
                      :model-value="submit.sections[currentSection].answers[i]"
                      @update:modelValue="ev => update(currentSection, i, ev)"
                      :ref="currentSection + '-' + i"
          />

          <CheckboxInput v-else-if="question.type === 'checkbox'"
                         :question="question"
                         :model-value="submit.sections[currentSection].answers[i]"
                         @update:modelValue="ev => update(currentSection, i, ev)"
                         :ref="currentSection + '-' + i"
          />

          <InfoInput v-else-if="question.type === 'info'"
                         :question="question"
                         :model-value="submit.sections[currentSection].answers[i]"
                         @update:modelValue="ev => update(currentSection, i, ev)"
                         :ref="currentSection + '-' + i"
          />
        </div>
      </div>

      <template v-if="isLastSection">
        <div>
          <button v-if="currentSection > 0" @click="back" class="btn btn-primary me-1">Zurück</button>
          <button @click="doSubmit" class="btn btn-success" type="button" :disabled="sending">
            <span v-show="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
            Abschicken
          </button>
        </div>
      </template>

      <template v-else>
        <div>
          <button v-if="currentSection > 0" @click="back" class="btn btn-primary">Zurück</button>
          <button @click="next" class="btn btn-primary">Weiter</button>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import TextInput from "./inputs/TextInput";
import RadioInput from "./inputs/RadioInput";
import CheckboxInput from "./inputs/CheckboxInput";
import {nextTick} from 'vue'
import InfoInput from "./inputs/InfoInput";

export default {
  name: "DynamicFormular",
  components: {InfoInput, CheckboxInput, RadioInput, TextInput},

  props: ["schema", "sending"],

  data() {
    let emptySubmit = {
      sections: []
    }

    for (let i = 0; i < this.schema.sections.length; i++) {

      let answers = new Array(this.schema.sections[i].questions.length);

      for (let x = 0; x < answers.length; x++) {
        answers[x] = {
          options: [],
          answer: null,
          customAnswer: null
        }
      }

      emptySubmit.sections.push({
        answers: answers
      })
    }

    return {
      currentSection: 0,
      submit: emptySubmit,
    }
  },

  methods: {

    update(currentSection, questionIndex, value) {
      this.submit.sections[currentSection].answers[questionIndex] = value;
    },

    validate() {

      for (let x = 0; x < this.schema.sections[this.currentSection].questions.length; x++) {
        if (!this.$refs[this.currentSection + '-' + x][0].validate()) {
          let el = this.$refs[this.currentSection + '-' + x][0].$el
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
          return false;
        }
      }

      return true;
    },

    async next() {

      if (!this.validate())
        return;

      this.currentSection++;

      await nextTick();

      const el = this.$el.getElementsByClassName('head-anchor')[0];

      if (el) {
        el.scrollIntoView({behavior: "smooth"});
      }
    },

    async back() {
      this.currentSection--;

      await nextTick();
      const el = this.$el.getElementsByClassName('head-anchor')[0];

      if (el) {
        el.scrollIntoView({behavior: "smooth"});
      }
    },

    doSubmit() {
      if (!this.validate())
        return;

      this.$emit("submitForm", this.submit)
    }
  },

  computed: {
    isLastSection() {
      return this.currentSection === this.schema.sections.length - 1;
    }
  }
}
</script>

<style scoped>
.card {
  border-top-color: #cdbab9;
  border-top-width: 5px;
}

.card.error {
  border-top-color: #bd5d58;
  border-top-width: 5px;
}
</style>