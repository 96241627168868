<template>
  <div class="container pm-container my-4">

    <div class="card p-3">
      <h3>Online Hautberatung</h3>

      <div v-if="!submitted">

        <div class="row">
          <div class="col-sm-12 col-md-2 my-3">
            <div class="d-flex justify-content-center">
              <img style="max-height: 80px; max-width: 80px;" src="miri.webp"
                   class="rounded-circle">
            </div>
          </div>
          <div class="col-sm-10">
            "Bei dieser Online Hautanalyse analysiere ich den Ist-Zustand Ihrer Haut und finde Ursachen und Lösungen
            auch
            bei
            Hautproblemen wie unreiner Haut, Rötungen, mangelnder Spannkraft, Falten, irritierter Haut, Neigung zu
            Unverträglichkeiten oder Ekzemen, schuppiger Haut …<br> Nehmen Sie sich dafür einen kurzen Moment Zeit und
            füllen
            Sie diesen Fragebogen aus."
            <div class="mt-2">
              <p class="fw-bold mb-0">Ihre Miriam Etti</p>
              <p class="fw-lighter">(staatlich anerkannte und hautärztlich geprüfte Kosmetikerin)
                <br>-pureMe Kosmetikinstitut Müllheim-</p>
            </div>
          </div>
        </div>

        <hr>

        <DynamicFormular v-if="schema" :schema="schema" @submitForm="submit" :sending="sending"></DynamicFormular>
      </div>
      <div v-else>

        <div class="row">
          <div class="col-sm-12 col-md-2 my-3">
            <div class="d-flex justify-content-center">
              <img style="max-height: 80px; max-width: 80px;" src="miri.webp"
                   class="rounded-circle">
            </div>
          </div>
          <div class="col-sm-10">
            "Vielen Dank für Ihre Anfrage! Ich werde mich bei Ihnen melden ☺"
            <div class="mt-2">
              <p class="fw-bold mb-0">Ihre Miriam Etti</p>
              <p class="fw-lighter">(staatlich anerkannte und hautärztlich geprüfte Kosmetikerin)
                -pureMe Kosmetik Müllheim-</p>
            </div>
          </div>
        </div>

        <div class="d-flex align-content-center flex-column">
            <a href="https://pureme.de" class="btn btn-primary">Zurück zur Website</a>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import DynamicFormular from "../../components/dynamicFormular/DynamicFormular";
import SkinAnalysisService from "../../service/skinAnalysis.service";

export default {
  name: "Hautanalysen",
  components: {DynamicFormular},

  data() {
    return {
      schema: null,
      submitted: false,
      sending: false
    }
  },

  mounted() {
    SkinAnalysisService.retrieveSchema()
        .then(schema => this.schema = schema);
  },

  methods: {
    submit(skinAnalysis) {
      this.sending = true;
      SkinAnalysisService.submit(skinAnalysis).then(() => this.submitted = true)
      .finally(
          () => this.sending = false
      )
      .catch(() => alert("Fehler beim Senden. Bitte versuchen Sie es später nocheinmal"))
    }
  }
}
</script>

<style scoped>

</style>