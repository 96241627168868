<template>
  <div>
    <QuestionTitle :question="question"></QuestionTitle>
    <div class="col-sm-12">
      <div class="input-group has-validation">
        <p v-html="question.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionTitle from "../QuestionTitle";

export default {
  name: "InfoInput",
  components: {QuestionTitle},
  props: ["question", "modelValue"],

  emits: ['update:modelValue'],

  data() {
    return {
      isInvalid: false,
      value: this.modelValue.answer
    }
  },

  methods: {
    onInput() {
      this.isInvalid = false;
      this.$emit('update:modelValue', {
        answer: this.value
      });
    },

    validate() {
      if (this.question.required && (!this.value || this.value?.length === 0)) {
        this.isInvalid = true;
        return false;
      }

      return true;
    }
  },

}
</script>

<style scoped>

</style>