<template>
  <div>
    <QuestionTitle :question="question"></QuestionTitle>
    <div class="col-sm-12">
      <fieldset>
        <div v-for="(option, i) in question.options" v-bind:key="i" class="form-check mb-2">
          <input :value="option" v-model="options" @update:modelValue="onInput" class="form-check-input" type="checkbox">
          <label class="form-check-label">
            {{ option }}
          </label>
        </div>
      </fieldset>
      <div>
        <div v-if="question.allowCustomOption" class="d-flex gap-2">
          <input v-model="customAnswerEnabled" @update:modelValue="onInputCustomAnswerEnabled" class="form-check-input" type="checkbox" :value="customAnswer">
          <input @update:modelValue="onInputCustomAnswer" v-model="customAnswer" type="text" class="form-control-sm form-control" placeholder="Eigene Antwort">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import QuestionTitle from "../QuestionTitle";

export default {
  name: "CheckboxInput",
  components: {QuestionTitle},
  props: ["question", "modelValue"],

  emits: ['update:modelValue'],

  data() {

    return {
      options: this.modelValue.options,
      customAnswer: this.modelValue.customAnswer,
      customAnswerEnabled: !!this.modelValue.customAnswer
    }
  },

  methods: {

    emit() {

    },

    onInput(value) {
      let answer = JSON.parse(JSON.stringify(value));

      let emit = {
        options: answer,
        customAnswer: this.customAnswer
      }

      this.$emit('update:modelValue', emit);
    },

    onInputCustomAnswer(value) {
      let answer = JSON.parse(JSON.stringify(this.options));

      let emit = {
        options: answer,
        customAnswer: value
      }

      this.$emit('update:modelValue', emit);
    },

    onInputCustomAnswerEnabled(value) {
      let answer = JSON.parse(JSON.stringify(this.options));

      let emit = {
        options: answer,
        customAnswer: value? this.customAnswer : null
      }

      this.$emit('update:modelValue', emit);
    },

    validate() {
      return true;
    }
  },

  watch: {
    customAnswer(newVal) {
      this.customAnswerEnabled = newVal !== '';
    },
  }
}
</script>

<style scoped>
.required {

}
</style>