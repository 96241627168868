<template>
  <h6 class="pb-2">{{ question.label }}<span class="required" v-if="question.required">*</span></h6>
</template>

<script>
export default {
  name: "QuestionTitle",

  props: ["question"]
}
</script>

<style scoped>

</style>